// /* utilities */
// .pt2 {
//   padding-top: 20px !important;
// }

// .pt3 {
//   padding-top: 30px !important;
// }

// .pt-medium {
//   padding-top: 50px !important;
// }

// .pb5 {
//   padding-bottom: 50px;
// }

// .pb10 {
//   padding-bottom: 100px;
// }

// .mt0 {
//   margin-top: 0 !important;
// }

// .mt1 {
//   margin-top: $default-font-size !important;
// }

// .mt2 {
//   margin-top: $default-font-size * 2 !important;
// }

// .mt-medium {
//   margin-top: 50px !important;
// }

// .mt-huge {
//   margin-top: 200px !important;
// }

// .mb-large {
//   margin-bottom: 80px !important;
// }

.center-children-xy {
    @include vertically-center-content();
    justify-content: center;
}

.spread-children {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    flex-direction: row;
}

.numeric {
    font-family: "Raleway", sans-serif;
}

.centered {
    text-align: center;
}

//   /*specilised utilities */
//   .centered-element {
//     display: block;
//     width: 45%;
//     margin-left: auto !important;
//     margin-right: auto !important;
//     text-align: center;
//   }

.text-upper {
    text-transform: uppercase;
}

.footnote {
    position: absolute;
    right: $content-padding-x;
    bottom: 0;
    font-style: italic;
    font-size: $default-font-size - 2px;
    transform: translateY(60px);
}

// .spread-children-x {
//   display: flex;
//   justify-content: space-around;
// }

.col.full-height {
    height: 100vh;
    min-height: 500px;
}
