.container > section {
    @include make-row(0);
    justify-content: center;
    @extend .px-5;
    @extend .py-5;
    @extend .single-background-centered;
    z-index: 2;
    position: relative;
    font-size: 12px;

    background-color: $color-hc-dark-blue;
    color: $text-color-light;

    a:not(.button) {
        color: $color-hc-dark-blue;
        &.active,
        &:hover {
            color: $color-hc-dark-pink;
        }
    }

    h1 {
        font-weight: 400;
        font-size: $default-font-size + 8px;
        margin: 0 0 40px 0;
    }

    h2 {
        font-weight: 700;
        font-size: $default-font-size;
    }

    h3 {
        font-weight: 500;
        font-size: $default-font-size;
    }

    figure {
        margin: 0;
        padding: 0;
        > div,
        > div img {
            width: 100%;

            &.square {
                width: 80%;
                margin: 0 auto;
            }
        }

        h1 {
            text-transform: uppercase;
            font-size: $default-font-size + 2px;
            margin-bottom: 30px;
        }

        &.pie-chart {
            h1 {
                width: 350px;
                text-align: center;
            }

            .chart-wrapper {
                width: 350px;
                height: 350px;
            }
        }
    }

    p,
    ul {
        &.indent {
            margin-left: 30px;
        }

        &.featured {
            margin: 20px 0;
            font-size: $default-font-size + 4px;
        }

        &.featured-extra {
            margin: 20px 0;
            font-size: $default-font-size + 15px;
            font-weight: 500;
        }

        &.centered {
            text-align: center;
            max-width: 90%;
            margin-left: auto;
            margin-right: auto;

            &.narrow {
                max-width: 0.4 * $content-width;
            }
        }

        &.light {
            color: $color-hc-dark-pink;
        }
    }

    ul li {
        max-width: 75%;
        padding: 2px 0;
    }

    > .expand-content {
        position: absolute;
        bottom: 5px;
        left: 5px;
    }

    // section alt styles

    &.light {
        background-color: $color-hc-pink;
        color: $color-hc-dark-blue;

        a {
            color: $color-hc-dark-blue;
        }

        .dates-select {
            background-color: $color-hc-pink;

            .picker {
                background-image: url("/assets/img/icons/down_arrow_dark.png");
            }

            &::before {
                background-image: url("/assets/img/icons/calendar_dark.png");
            }
        }

        .chart-wrapper {
            text {
                fill: $color-hc-dark-blue;
            }

            .chart-exporter {
                background-image: url("/assets/img/icons/ic-file-download-dark.svg");
            }
        }

        > .expand-content {
            background-image: url("/assets/img/icons/popout-dark.svg");
        }
    }
}

.cover-page {
    position: relative;
    height: 75vh;
    background-color: transparent;
    background-image: url("/assets/img/backgrounds/home_stretch.png");
    background-position: center;
    min-height: 400px;
    max-height: 686px;
    color: $color-hc-dark-blue;
    @extend .center-children-xy;

    .logo {
        position: absolute;
        top: 10px;
        left: 30px;
    }

    > header,
    > .feature {
        max-width: $content-width / 2;
        text-align: center;
        font-size: $default-font-size + 16px;
        font-weight: 500;

        h1 {
            text-transform: uppercase;
            font-weight: 700;
            font-size: $default-font-size + 23px;
            border-bottom: 1px solid $color-hc-dark-blue;
            padding: 5px 0;
            margin: 0 0 40px 0;
        }
    }

    .frontpage-tagline {
        position: absolute;
        top: 100px;
        right: 30px;
        font-size: $default-font-size;
    }
}

.intense-quote {
    //width: $content-width/2.4;
    text-align: center;
    border-top: 1px solid $color-hc-dark-blue;
    padding-top: 20px;
    font-weight: 500;
    font-size: $default-font-size + 8px;
    margin-top: 50px;

    &.light {
        color: $color-hc-dark-pink;
        border-color: $color-hc-dark-pink;
    }
}

.animated-symbol {
    @extend .single-background-top-left;
    background-size: 100%;
    position: absolute;
    width: 150px;
    height: 150px;
    top: 250px;
    right: 50px;
    background-image: url("/assets/img/backgrounds/symbol/1.png");

    animation-name: transition-bg;
    animation-duration: 20s;
    animation-iteration-count: infinite;
    animation-direction: alternate;

    @include media-breakpoint-down(md) {
        width: 100px;
        height: 100px;
        top: initial;
        right: 25px;
        bottom: 25px;
    }
}

@keyframes transition-bg {
    from {
        background-image: url("/assets/img/backgrounds/symbol/1.png");
    }
    20% {
        background-image: url("/assets/img/backgrounds/symbol/2.png");
    }
    40% {
        background-image: url("/assets/img/backgrounds/symbol/3.png");
    }
    60% {
        background-image: url("/assets/img/backgrounds/symbol/4.png");
    }
    80% {
        background-image: url("/assets/img/backgrounds/symbol/5.png");
    }
    to {
        background-image: url("/assets/img/backgrounds/symbol/6.png");
    }
}

.styalised-graph {
    z-index: 0;
    position: absolute;
    bottom: 25px;
    left: 0;
    width: 100%;
    height: 83px;
    background-image: url("/assets/img/backgrounds/styalised-graph-stretched.png");
    @extend .single-background-top-left;

    animation-name: animate-width;
    animation-duration: 20s;
    animation-iteration-count: infinite;
}

@keyframes animate-width {
    from {
        width: 0%;
    }
    80% {
        width: 100%;
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
