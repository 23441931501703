@import "variables-mixins";
@import "~bootstrap/scss/bootstrap-grid.scss",
    "~bootstrap/scss/mixins/_breakpoints.scss",
    "~bootstrap/scss/utilities/_api.scss"; // not using bootstrap but no need to reinvent the wheel here
@import "utilities";

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&family=Raleway:wght@400;500&display=swap&family=Proxima Nova:wght@300;400;500;700");
// Reset the box-sizing
// Box sizing
@mixin box-sizing($boxmodel) {
    -webkit-box-sizing: $boxmodel;
    -moz-box-sizing: $boxmodel;
    box-sizing: $boxmodel;
}
//
// Heads up! This reset may cause conflicts with some third-party widgets.
// For recommendations on resolving such conflicts, see
// https://getbootstrap.com/docs/3.4/getting-started/#third-box-sizing
* {
    @include box-sizing(border-box);
}
*:before,
*:after {
    @include box-sizing(border-box);
}

///

body {
    background-color: $background-color;
    font-family: "Raleway", Arial, Helvetica, sans-serif;
    color: $color-hc-dark-blue;
    margin: 0;
    padding: 0;
    /*If the content is withing viewport, center container on page*/
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

// defaults

figure {
    padding: 0;
    margin: 0;
}

/* Brand */

.logo img {
    width: 150px;

    & + .tagline {
        font-size: $default-font-size;
    }
}

/* top-level containers */

.container {
    @include make-container(0);
    max-width: 1400px;

    //TODO - move
    h2.logo-heading {
        text-transform: uppercase;
        font-weight: 400;
        font-size: $default-font-size + 2px;
        text-align: center;
        border-bottom: 1px solid;
        padding-bottom: 15px;

        &:not(:first-child()) {
            margin-top: 50px;
        }

        &::before {
            content: url("../assets/img/logo/icon.svg");
            display: inline-block;
            vertical-align: middle;
        }
    }
}

@import "reports", "charting", "modals", "interaction", "tables", "reports-nav";
