.chart-wrapper {
    position: relative;

    text {
        fill: $text-color-light;
    }

    .donught-slice-text {
        @extend .center-children-xy;
        position: absolute;
        top: 35%;
        left: 35%;
        width: 30%;
        height: 30%;
        overflow: visible;
        text-align: center;

        .numeric {
            font-size: $default-font-size + 25px;
            display: block;
            padding-bottom: 10px;
        }

        &::before {
            content: "";
            display: block;
            position: absolute;
            top: -70%;
            right: 20%;
            height: 80%;
            width: 1px;
            background-color: $text-color-light;
            transform: rotate(10deg);
        }
    }

    .chart-exporter {
        @extend .single-background-top-left;
        position: absolute;
        bottom: 0;
        left: 0;
        cursor: pointer;
        z-index: 999;
        padding: 5px 0 5px 30px;
        background-image: url("/assets/img/icons/ic-file-download.svg");
    }

    > h2 {
        font-size: $default-font-size + 2px;
        position: absolute;
        top: -20px;
        left: 0;
    }
}

//Override google css, this is non-standard so monitor for issues
.google-visualization-tooltip {
    pointer-events: none; //Fix flashing
    border: 0 !important;
    border-radius: 0 !important;
    background-color: transparent !important;
    box-shadow: none !important;
    background-image: url("/assets/img/backgrounds/bubble-snout.svg");
    background-size: 100%;
    background-repeat: no-repeat;

    .chart-tooltip,
    ul {
        width: 100px;
        height: 95px;
        color: $color-hc-dark-blue;
        @extend .center-children-xy;
        text-align: center;
        padding: 0 5px !important;

        span,
        li {
            display: block;
        }
    }

    ul {
        flex-direction: column;
        margin: 0 !important;
        li {
            padding: 0 !important;
            margin: 0 !important;
        }
    }

    .on-select g g g rect {
        cursor: pointer;
    }
}
