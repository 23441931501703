nav.tabbed {
    @include make-container(0);

    background-color: $color-hc-yellow;
    border-radius: 10px 0 0 10px;

    a,
    a:hover {
        color: white;
        text-decoration: none;
    }

    > ol {
        @include make-row();
        padding: 0;
        margin: 0;

        a {
            @include make-col-ready();
            @include media-breakpoint-up(sm) {
                @include make-col(6);
            }
            @include media-breakpoint-up(md) {
                @include make-col(3);
            }

            text-transform: uppercase;
            text-align: center;
            font-size: 24px;
            padding: 20px 0;
            border-radius: 10px;

            &.active {
                background-color: transparent !important;
                cursor: default;
            }
            &:nth-child(1) {
                background-color: $color-hc-purple;
            }
            &:nth-child(2) {
                background-color: $color-hc-dark-blue;
            }
            &:nth-child(3) {
                background-color: $color-hc-darker-pink;
            }
            &:nth-child(4) {
                background-color: $color-hc-dark-aqua;
            }
        }
    }

    nav {
        @include make-row();
        padding: 10px 0;
        text-transform: uppercase;
        font-size: 12px;

        a {
            @include make-col();
            text-align: center;
        }
    }
}
