.dates-select {
    $date-picker-height: $default-font-size + 2px;
    height: $date-picker-height;
    font-size: $date-picker-height;
    text-transform: uppercase;
    border-bottom: 1px solid;
    padding-bottom: 30px;
    margin: 0 0 30px 30px;
    overflow: visible;
    max-width: 375px;
    background-color: inherit;
    position: relative;
    background-color: $color-hc-dark-blue;

    > .picker {
        position: relative;
        display: inline-block;
        background-image: url("/assets/img/icons/down_arrow_light.png");
        background-repeat: no-repeat;
        background-position: right center;
        background-size: 20px;
        padding-right: 50px;
        cursor: pointer;
        background-color: inherit;
    }

    .options {
        display: none;
        position: absolute;
        left: 0;
        top: $date-picker-height;
        padding: 5px 0 0 0;
        width: 100%;
        background-color: inherit;

        .option {
            display: block;
            cursor: pointer;
            padding: 5px 0;
            font-size: $default-font-size - 2px;

            &:hover {
                background-color: $color-hc-yellow;
                color: $color-hc-dark-blue;
            }

            &.selected {
                font-weight: 700;
            }
        }
    }

    &::before {
        content: "";
        @extend .single-background-top-left;
        background-image: url("/assets/img/icons/calendar_light.png");
        background-size: 100%;
        width: 20px;
        height: 20px;
        display: block;
        position: absolute;
        margin-left: -40px;
    }

    &.open {
        .options {
            display: block;
            width: 100%;
            float: left;
        }
    }
}

input[type="text"] {
    background-color: transparent;
    border-width: 1px 0;
    border-color: $color-hc-dark-blue;
    height: $default-font-size + 10px;
    width: $content-width/4;

    &.centered {
        display: block;
        margin: 20px auto;
    }

    &::placeholder {
        color: $color-hc-dark-pink;
    }
}

form {
    font-size: 0px;

    input {
        background-color: transparent;
        border: 1px solid white;
        border-radius: 0;
        padding: 5px;
        height: 30px;
        color: $text-color-light;
        font-size: $default-font-size;

        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    input[type="text"],
    input[type="email"] {
        min-width: 180px;

        &::placeholder {
            color: $text-color-light;
        }
    }
}

button {
    box-sizing: border-box;
}

button,
.button {
    display: inline-block;
    text-align: center;
    background-color: $color-hc-dark-blue;
    border: 0;
    color: $text-color-light;
    font-size: $default-font-size;
    padding: 10px;
    text-decoration: none;
    cursor: pointer;
    font-weight: 700;

    &:hover {
        background-color: $color-hc-yellow;
        color: $color-hc-dark-blue;
    }
}

.toggle-switch {
    position: relative;
    width: 75px;
    display: inline-block;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    text-align: left;
    &-checkbox {
        display: none;
    }
    &-label {
        display: block;
        overflow: hidden;
        cursor: pointer;
        border: 0 solid #bbb;
        border-radius: 20px;
        margin: 0;
    }
    &-inner {
        display: block;
        width: 200%;
        margin-left: -100%;
        transition: margin 0.3s ease-in 0s;
        &:before,
        &:after {
            display: block;
            float: left;
            width: 50%;
            height: 30px;
            padding: 0;
            line-height: 30px;
            font-size: 12px;
            color: white;
            font-weight: bold;
            box-sizing: border-box;
        }
        &:before {
            content: attr(data-yes);
            text-transform: uppercase;
            padding-left: 10px;
            background-color: rgb(240, 162, 38);
            color: #fff;
        }
    }
    &-disabled {
        background-color: #ddd;
        cursor: not-allowed;
        &:before {
            background-color: #ddd;
            cursor: not-allowed;
        }
    }
    &-inner:after {
        content: attr(data-no);
        text-transform: uppercase;
        padding-right: 10px;
        background-color: $color-hc-dark-blue;
        color: #fff;
        text-align: right;
    }
    &-inner.contrast:after {
        background-color: $color-hc-light-aqua;
        color: $color-hc-dark-blue;
    }
    &-switch {
        display: block;
        width: 20px;
        height: 20px;
        margin: 5px;
        background: #fff;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 40px;
        border: 0 solid #bbb;
        border-radius: 20px;
        transition: all 0.3s ease-in 0s;
    }
    &-checkbox:checked + &-label {
        .toggle-switch-inner {
            margin-left: 0;
        }
        .toggle-switch-switch {
            right: 0px;
        }
    }

    &.small-switch {
        width: 40px;
        .toggle-switch-inner {
            &:after,
            &:before {
                content: "";
                height: 20px;
                line-height: 20px;
            }
        }
        .toggle-switch-switch {
            width: 16px;
            right: 20px;
            margin: 2px;
        }
    }

    &.wide-switch {
        width: 150px; //75
        .toggle-switch-switch {
            right: 120px; //40px
        }
    }

    &.lower-right {
        position: absolute;
        bottom: 10px;
        right: 10px;
    }
    &.upper-left {
        position: absolute;
        top: 20px;
        right: 10px;
    }
}
