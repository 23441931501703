.expand-content {
    cursor: pointer;
    @extend .single-background-top-left;
    padding: 5px 5px 5px 30px;
    background-image: url("/assets/img/icons/popout-light.svg");

    &:hover,
    &.open {
        color: $color-hc-yellow;
        background-image: url("/assets/img/icons/popout-hover.svg");
    }
}

.modal-overlay {
    position: fixed;
    inset: 0px;
    background-color: rgba(255, 255, 255, 0.75);
    z-index: 9999;
    height: 100vh;
    width: 100vw;
    padding: 2vh 2vw;
}

.section-expand-modal {
    @include make-container(0);
    padding: 20px;
    overflow-x: hidden;
    // $m-pad: 20px;
    // $m-bd: 0px;
    // $m-width: $content-width - (2 * ($m-pad + $m-bd));
    // $m-left-margin: -($m-width / 2) - $m-pad - $m-bd;

    position: absolute;
    //inset: 50px;
    //border: $m-bd solid $color-hc-dark-blue;
    background: $color-hc-pink;
    overflow: auto;
    border-radius: 10px;
    outline: none;
    //padding: $m-pad;

    top: 2vh;
    //left: 50%;
    width: 96vw;
    margin: 0 auto;
    height: 96vh;
    overflow-y: auto;

    box-shadow: 2px 2px 20px 5px #888888;

    .row {
        justify-content: center;
    }

    > h1 {
        font-size: $default-font-size + 5px;
        text-transform: uppercase;
        margin: 0 0 10px 0;
    }

    > h2 {
        font-size: $default-font-size + 3px;
    }

    > h3 {
        font-size: $default-font-size;
        margin: 0;
    }

    .button.close {
        position: absolute;
        right: 10px;
        top: 10px;
        border-radius: 50%;
        font-size: 12px;
        line-height: 12px;
        width: 12px;
        height: 12px;
        padding: 8px;
        z-index: 99999;
    }

    .breakdown-table {
        //width: auto;
        //min-width: $m-width / 2;
        margin-left: auto;
        margin-right: auto;
    }

    .chart-wrapper {
        width: 100%;
        height: 250px;

        &.short {
            height: 150px;
        }

        text {
            fill: $color-hc-dark-blue;
        }

        .donught-slice-text {
            display: none;
        }

        .chart-exporter {
            background-size: 20px;
            font-size: $default-font-size - 2px;
            padding: 5px 0 5px 20px;
        }
    }

    section {
        > h1 {
            font-size: $default-font-size + 3px;
            text-transform: uppercase;
            margin: 0 0 30px 0;
        }
    }

    table {
        th {
            font-size: $default-font-size + 2px;
        }
        td {
            font-size: $default-font-size;
        }
    }

    /* animate */

    animation: expand-box 1s;
    animation-fill-mode: forwards;

    > * {
        animation: fadein 1s;
        animation-fill-mode: forwards;
    }

    @keyframes fadein {
        from {
            opacity: 0;
        }
        75% {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    @keyframes expand-box {
        from {
            //width: 0;
            max-height: 0;
            margin-left: 0;
            overflow: hidden;
        }
        // 25% {
        //   max-height: 0;
        //   //width: 95vw;
        //   //margin-left: $m-left-margin;
        // }
        to {
            overflow: visible;
            overflow-y: auto;
            max-height: 95vh;
        }
    }
}

.modal-small {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 200px;
    width: 300px;
    margin-top: -100px;
    margin-left: -150px;
    background-color: white;
    border: 1px solid $color-hc-dark-blue;

    .close-modal {
        position: absolute;
        right: 0;
        top: 0;
    }

    button:not(.close-modal),
    a {
        display: block !important;
        margin: 10px auto;
        width: 50%;
    }

    h1 {
        font-size: $default-font-size + 5px;
        margin: 20px 10px;
    }
}
