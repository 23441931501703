$default-font-size: 12px;

$content-width: 960px;
$full-page-height: 530px;
$content-top-margin: 10px;
$content-padding-x: 30px;

$color-hc-yellow: #f3a748;
$color-hc-dark-blue: #0c0341;
$color-hc-pink: #ffe0e9;
$color-hc-pink-med: #febfd4;
$color-hc-dark-pink: #fd6092;
$color-hc-darker-pink: #e74b7e;
$color-hc-light-aqua: #bcf8ec;
$color-hc-dark-aqua: #5de1e6;
$color-hc-purple: #6d658e;

$text-color-light: #ceccd9;

$background-color: #f9f8fc;

/* tablet */

$content-width-tablet: 700px;
$content-width-phone: 640px;

/* Mixins etc */

@mixin vertically-center-content {
    display: flex;
    align-items: center; /* align vertical */
}

.single-background-top-left {
    background-repeat: no-repeat;
    background-position: 0 0;
}

.single-background-centered {
    background-repeat: no-repeat;
    background-position: center;
}

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px,
) !default;
